$primary: #d22027 !default;
$secondary: #226fc7 !default;
$info: #ebc40e !default;

/* Darken */
$primaryDarken: darken($primary, 5%);
$secondaryDarken: darken($secondary, 5%);
$infoDarken: darken($info, 5%);

/* Rest */
$tertiary: #f2f2f2;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$mianNavHeight: 0;
